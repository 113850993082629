import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faPaperPlane, faDonate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPaperPlane, faCoffee, faDonate);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
