<template>
    <div id="app">
        <router-link to="/Impressum" id="link-to-impressum">Impressum</router-link>
        <Navigation></Navigation>
        <transition name="fade">
            <router-view/>
        </transition>
    </div>
</template>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Coda|Marck+Script');

    :root {
        --red: #900000;
        --purple: #5F0F40;
        --orange: #E36414;
        --blue: #0F4C5C;
    }

    * {
        margin: 0;
        padding: 0;
    }

    ul, ol {
        margin: 1em;
        padding-left: 1em;
    }

    .coda {
        font-family: 'Coda', cursive;
    }

    .marck-script {
        font-family: 'Marck Script', cursive;
    }

    body {
        background-image: url('./assets/bg_white.jpg');
        background-position-x: 0;
        background-position-y: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        min-height: 100vh;
    }

    #app {
        font-size: 18px;
        padding: 2rem 2rem 2rem 2rem;
        margin: 0 auto;
        max-width: 1920px;
    }

    .hover-animation:hover {
        background-image: linear-gradient(90deg, var(--blue), var(--purple), var(--blue), var(--purple)) !important;
        background-size: 300% 200% !important;

        animation: button-hover 2s linear infinite !important;
    }

    @keyframes button-hover {
        0% {
            background-position: left bottom;
        }
        100% {
            background-position: right bottom;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    #link-to-impressum {
        all: unset;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 0.25rem 0.25rem 0.5rem;
        background: var(--red);
        opacity: 0.3;
        color: white;
        font-family: 'Coda', cursive;
        border-bottom-left-radius: 1rem;
        cursor: pointer;
        transition: opacity 0.4s;
    }

    #link-to-impressum:hover {
        opacity: 1;
    }

    @media only screen and (max-width: 850px) {
        #app {
            padding: 1rem;
        }
    }
</style>

<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Navigation from '@/components/Navigation.vue';

    @Component({
        components: {Navigation},
    })
    export default class App extends Vue {
    }
</script>
