import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "HomeView" */ './views/Home.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import(/* webpackChunkName: "AboutView" */ './views/About.vue'),
    },
    {
      path: '/work',
      name: 'Work',
      component: () => import(/* webpackChunkName: "WorkView" */ './views/Work.vue'),
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import(/* webpackChunkName: "ContactView" */ './views/Contact.vue'),
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import(/* webpackChunkName: "ImpressumView" */ './views/Impressum.vue'),
    },
    {
      path: '*',
      name: 'Not Found',
      component: () => import(/* webpackChunkName: "NotFoundView" */ './views/NotFound.vue'),
    },
  ],
});
