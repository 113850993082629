<template>
  <nav>
    <div id="logo">
      <img src="../assets/Logo.png">
    </div>
    <div id="links">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/work">Work</router-link>
      <router-link to="/contact">Contact</router-link>
    </div>
  </nav>
</template>

<style scoped>
nav {
  display: flex;
}

#logo {
  height: 5rem;
  flex: auto;
}

#logo img {
  height: 100%;
}

#links {
  line-height: 5rem;
}

#links a {
  all: unset;
  margin: 0 1.25rem;
  padding: 0 0.25rem;
  font-family: "Coda", cursive;
  cursor: pointer;
  border-left: 2px solid transparent;
  transition: border-left-color 0.4s, color 0.4s 0.2s;
}

#links a:hover {
  border-left-color: var(--red);
  color: var(--red);
}

#links a.router-link-exact-active {
  color: var(--red);
  border-left-color: transparent;
  cursor: inherit;
}

/* nav is too large here */
@media only screen and (max-width: 850px) {
  nav {
    display: block;
    text-align: center;
  }

  #logo {
    display: none;
  }
  
  #links a {
    margin: 0 .25rem;
    padding: 0 0.25rem;
  }
}
</style>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Navigation extends Vue {}
</script>
